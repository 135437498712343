import axios from "axios";
import {
  calculateTotalForInvoices,
  getFormattedDate,
  storeLog,
  validateForm,
} from "../../utils";
import { API_BASE_URL } from "../../config/constants";

/**
 * Handles the payment process using the Basys Payment gateway.
 *
 * - If a customer ID exists, attempts to retrieve the payment method ID and process the payment.
 * - If no customer ID exists or the payment method ID is not found, creates a new customer and attempts to process the payment again.
 *
 * @param {Event} e - The event object from the form submission.
 * @param {string} accName - The account name for the payer.
 * @param {Function} getCardId - Function to retrieve the payment method ID.
 * @param {Object} basysCustomerID - The Basys customer ID reference object.
 * @param {Function} setLoading - Function to set the loading state.
 * @param {Array<Object>} invoiceItems - List of invoice items being paid.
 * @param {Object} basysAccountInfo - Account information for the Basys customer.
 * @param {Object} paynow - Payment options related to the current transaction.
 * @param {boolean} isBasysTesting - Indicates if the Basys environment is in testing mode.
 * @param {Object} basysBillingAddrInfo - Billing address information.
 * @param {Object} formData - Form data for the payment, including card details.
 * @param {string} basysApiToken - API token for authenticating with Basys.
 * @param {Array<number>} selectedRows - Selected rows representing items being paid for.
 * @param {Object} sendrespons - Response object to send as part of the payment.
 * @param {string} endpoint - API endpoint URL.
 * @param {string} guid - GUID for the transaction.
 * @param {string} corpid - CorpID associated with the transaction.
 * @param {string} token - Authorization token for the request.
 * @param {Function} setFailedIsOpen - Function to control the display of a failure modal.
 * @param {Function} setISOpen - Function to control the display of a success modal.
 * @param {Array<Object>} data - The data object used in the transaction.
 * @param {Function} setShowCardSaveModal - Function to control the display of a "Save Card" modal.
 * @param {Function} setIsCardSaveOpen - Function to control the display of the card saving process.
 * @param {Function} setCardHasBeenSaved - Function to control the state when the card is saved.
 * @param {Function} setErrors - Function to set error messages.
 * @param {Function} setPaymentFailureReason - Function to set the reason for payment failure.
 * @param {Function} setIsCardSaveFailedOpen - Function to control the display of the card save failure modal.
 *
 * @returns {Promise<void>} A promise that resolves when the payment process is complete.
 */
export const basysPayment = async (
  e,
  accName,
  getCardId,
  basysCustomerID,
  setLoading,
  invoiceItems,
  basysAccountInfo,
  paynow,
  isBasysTesting,
  basysBillingAddrInfo,
  formData,
  basysApiToken,
  selectedRows,
  sendrespons,
  endpoint,
  guid,
  corpid,
  token,
  setFailedIsOpen,
  setISOpen,
  data,
  setShowCardSaveModal,
  setIsCardSaveOpen,
  setCardHasBeenSaved,
  setErrors,
  setPaymentFailureReason,
  setIsCardSaveFailedOpen
) => {
  e.preventDefault();

  let payment_method_id = "";

  if (basysCustomerID.current) {
    [payment_method_id] = await getCardId();
    if (!payment_method_id) {
      await createCustomer(
        accName,
        getCardId,
        true,
        basysCustomerID,
        setLoading,
        basysAccountInfo,
        isBasysTesting,
        basysBillingAddrInfo,
        formData,
        basysApiToken,
        sendrespons,
        endpoint,
        guid,
        corpid,
        token,
        setShowCardSaveModal,
        setIsCardSaveOpen,
        setCardHasBeenSaved,
        setErrors,
        invoiceItems,
        paynow,
        selectedRows,
        setFailedIsOpen,
        setISOpen,
        data,
        setPaymentFailureReason,
        setIsCardSaveFailedOpen
      );
      [payment_method_id] = await getCardId();
    }
    await handleBasysCheckout(
      accName,
      payment_method_id,
      setLoading,
      invoiceItems,
      basysCustomerID,
      basysAccountInfo,
      paynow,
      isBasysTesting,
      basysBillingAddrInfo,
      formData,
      basysApiToken,
      selectedRows,
      sendrespons,
      endpoint,
      guid,
      corpid,
      token,
      setFailedIsOpen,
      setISOpen,
      data,
      setErrors,
      setPaymentFailureReason
    );
  } else {
    await createCustomer(
      accName,
      getCardId,
      true,
      basysCustomerID,
      setLoading,
      basysAccountInfo,
      isBasysTesting,
      basysBillingAddrInfo,
      formData,
      basysApiToken,
      sendrespons,
      endpoint,
      guid,
      corpid,
      token,
      setShowCardSaveModal,
      setIsCardSaveOpen,
      setCardHasBeenSaved,
      setErrors,
      invoiceItems,
      paynow,
      selectedRows,
      setFailedIsOpen,
      setISOpen,
      data,
      setPaymentFailureReason,
      setIsCardSaveFailedOpen
    );
  }
};

/**
 * Handles the Basys payment checkout process by sending a payment request to the Basys Payment gateway.
 *
 * - Validates the form data and calculates the total amount due for the selected invoice items.
 * - Sends the payment request using the Basys API, processes the response, and updates invoice items based on the transaction status.
 * - If successful, sends a PUT request to update the payment status in the backend.
 *
 * @param {string} accName - The account name for the payer.
 * @param {string} payment_method_id - The ID of the payment method being used.
 * @param {Function} setLoading - Function to control the loading state.
 * @param {Array<Object>} invoiceItems - List of invoice items being processed.
 * @param {Object} basysCustomerID - Reference to the Basys customer ID.
 * @param {Object} basysAccountInfo - Account information for the Basys customer.
 * @param {Object} paynow - Payment options related to the transaction.
 * @param {boolean} isBasysTesting - Boolean indicating if the payment is in a testing environment.
 * @param {Object} basysBillingAddrInfo - Billing address information for the customer.
 * @param {Object} formData - Form data containing payment details.
 * @param {string} basysApiToken - API token for Basys authentication.
 * @param {Array<number>} selectedRows - List of selected rows corresponding to invoice items being paid.
 * @param {Object} sendrespons - The response object to update and send with the payment request.
 * @param {string} endpoint - The API endpoint URL.
 * @param {string} guid - GUID for the transaction.
 * @param {string} corpid - CorpID associated with the transaction.
 * @param {string} token - Authorization token for the request.
 * @param {Function} setFailedIsOpen - Function to control the display of the failure modal.
 * @param {Function} setISOpen - Function to control the display of the success modal.
 * @param {Array<Object>} data - Data used in the transaction process.
 * @param {Function} setErrors - Function to set validation errors.
 * @param {Function} setPaymentFailureReason - Function to set the reason for a payment failure.
 *
 * @returns {Promise<void>} A promise that resolves when the payment process and backend update are completed.
 * @throws {Error} Throws an error if the payment fails or there is an issue with updating the backend.
 */
const handleBasysCheckout = async (
  accName,
  payment_method_id,
  setLoading,
  invoiceItems,
  basysCustomerID,
  basysAccountInfo,
  paynow,
  isBasysTesting,
  basysBillingAddrInfo,
  formData,
  basysApiToken,
  selectedRows,
  sendrespons,
  endpoint,
  guid,
  corpid,
  token,
  setFailedIsOpen,
  setISOpen,
  data,
  setErrors,
  setPaymentFailureReason
) => {
  setLoading(true);

  const payload = {
    type: "sale",
    amount: parseFloat(
      calculateTotalForInvoices(
        invoiceItems.map((_, index) => {
          return {
            index,
          };
        }),
        data,
        selectedRows
      )
    ),
    description: basysAccountInfo?.AccountName,
    email_receipt: true,
    email_address: paynow.email,
    create_vault_record: false,
    payment_method: {
      customer: {
        id: basysCustomerID.current,
        payment_method_type: "card",
        payment_method_id,
      },
    },
    pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
    IsTesting: isBasysTesting,
    billing_address: basysBillingAddrInfo,
    guid: guid,
    corp_id: corpid,
    action_type: "Basys Payment handleBasysCheckout",
  };

  if (validateForm(formData, setErrors)) {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/basys/create-transaction`,
        payload,
        {
          headers: {
            Authorization: basysApiToken,
            "Content-Type": "application/json",
          },
        }
      );

      const responseCode = response.data.data.response_code;

      console.log("RESPONSE CODE", responseCode);

      if (responseCode === 100) {
        // Do nothing
      } else if (responseCode === 99) {
        setPaymentFailureReason(
          `${response?.data?.data?.response_code}: ${response?.data?.data?.response} - ${response?.data?.data?.response_body?.card?.processor_response_text}`
        );
        throw new Error("Payment Pending");
      } else if (responseCode === 110) {
        setPaymentFailureReason(
          `${response?.data?.data?.response_code}: ${response?.data?.data?.response} - ${response?.data?.data?.response_body?.card?.processor_response_text}`
        );
        throw new Error("Payment partially approved");
      } else if (responseCode >= 200 && responseCode <= 299) {
        setPaymentFailureReason(
          `${response?.data?.data?.response_code}: ${response?.data?.data?.response} - ${response?.data?.data?.response_body?.card?.processor_response_text}`
        );
        throw new Error("Payment declined");
      } else if (responseCode >= 300 && responseCode <= 399) {
        setPaymentFailureReason(
          `${response?.data?.data?.response_code}: ${response?.data?.data?.response} - ${response?.data?.data?.response_body?.card?.processor_response_text}`
        );
        throw new Error("Payment declined");
      } else if (responseCode >= 400 && responseCode <= 499) {
        setPaymentFailureReason(
          `${response?.data?.data?.response_code}: ${response?.data?.data?.response} - ${response?.data?.data?.response_body?.card?.processor_response_text}`
        );
        throw new Error("Transaction Processor Error");
      } else {
        setPaymentFailureReason(
          `${response?.data?.data?.response_code}: ${response?.data?.data?.response} - ${response?.data?.data?.response_body?.card?.processor_response_text}`
        );
        throw new Error("Payment status unknown");
      }

      if (response.data.msg === "success") {
        let putData = sendrespons; //copy data
        let invoiceItemsTmp = [];

        const itemType = sendrespons.ItemType;
        let itemTypeKey = "";

        if (itemType === 0) {
          itemTypeKey = "InvoiceItems";
        } else if (itemType === 1) {
          itemTypeKey = "CiaByWeekItems";
        } else if (itemType === 2) {
          itemTypeKey = "CiaByMonthItems";
        } else if (itemType === 3) {
          itemTypeKey = "CiaPrepayItems";
        }

        if (selectedRows.length === 0) {
          setLoading(false);

          if (itemType === 0) {
            putData.InvoiceItems = invoiceItems;
          } else if (itemType === 1) {
            putData.CiaByWeekItems = invoiceItems;
          } else if (itemType === 2) {
            putData.CiaByMonthItems = invoiceItems;
          } else if (itemType === 3) {
            putData.CiaPrepayItems = invoiceItems;
          }
        } else {
          for (let i = 0; i < putData[itemTypeKey].length; i++) {
            if (
              selectedRows.includes(i) &&
              Number(
                putData[itemTypeKey][i].PaymentResponse?.PaymentAmount.replace(
                  ",",
                  ""
                )
              ) > 0
            ) {
              const updatedPaymentResponse = {
                TransactionDate: getFormattedDate(),
                TransactionID: response?.data?.data?.id,
                ReferenceNumber:
                  response?.data?.data?.referenced_transaction_id,
                PaymentStatus: 1,
                PaymentAmount:
                  putData[itemTypeKey][i].PaymentResponse?.PaymentAmount,
                ResponseCode:
                  response?.data?.data?.response_body?.card?.response_code,
                ResponseMessage:
                  response?.data?.data?.response_body?.card?.response,
              };
              putData[itemTypeKey][i].PaymentResponse = updatedPaymentResponse;
              invoiceItemsTmp.push(putData[itemTypeKey][i]);
            } else {
              invoiceItemsTmp.push(invoiceItems[i]);
            }
          }
          if (itemType === 0) {
            putData.InvoiceItems = invoiceItemsTmp;
          } else if (itemType === 1) {
            putData.CiaByWeekItems = invoiceItemsTmp;
          } else if (itemType === 2) {
            putData.CiaByMonthItems = invoiceItemsTmp;
          } else if (itemType === 3) {
            putData.CiaPrepayItems = invoiceItemsTmp;
          }
        }
        putData.TheMonerisCC = {
          ...putData.TheMonerisCC,
          CcType: response?.data?.data?.response_body?.card?.card_type,
          Last4Num: response?.data?.data?.response_body?.card?.last_four,
        };
        putData.BasysCustomerID = basysCustomerID.current;
        putData.guid = guid;
        putData.corp_id = corpid;
        try {
          const url = `${endpoint}/Put?id=${guid}`;
          const putResponse = await fetch(url, {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "*/*",
              Origin_Custom: endpoint,
            },
            body: JSON.stringify(putData),
          });
          setLoading(false);
          const responseData = await putResponse.json();
          storeLog(
            "info",
            `Basys Payment PUT request: "${JSON.stringify(putData)}"`,
            200,
            guid,
            corpid,
            "Basys Payment",
            `Basys Payment for ${accName}`,
            "Success"
          );
          // Handle the response here as needed
        } catch (error) {
          storeLog(
            "error",
            `Basys Payment PUT request: "${JSON.stringify(putData)}"`,
            200,
            guid,
            corpid,
            "Basys Payment",
            `Basys Payment for ${accName}`,
            "Failure"
          );
          setLoading(false);
          setFailedIsOpen(true);
          console.error("Error fetching data:", error);
          return;
          // Handle error here
        }

        setLoading(false);
        setISOpen(true);
      } else {
        setLoading(false);
        setFailedIsOpen(true);
      }
    } catch (error) {
      setLoading(false);
      setFailedIsOpen(true);
      console.error("Error occurred while making API call:", error);
    }
  } else {
    setLoading(false);
  }
};

/**
 * Sends a PUT request to save the card details for the customer using the Basys payment system.
 *
 * - Updates the `sendrespons` payload to indicate that the card is saved and includes Moneris card details.
 * - Sends the updated payload to the backend via a PUT request to save the customer's card details.
 * - Logs the success or failure of the operation using `storeLog`.
 *
 * @param {string} accName - The account name for the payer.
 * @param {string} basysCustomerID - The Basys customer ID for the payer.
 * @param {Object} monerisCc - Moneris credit card details object.
 * @param {Object} sendrespons - The response object to update and send with the PUT request.
 * @param {string} endpoint - API endpoint URL for the PUT request.
 * @param {string} guid - GUID for the transaction.
 * @param {string} corpid - CorpID associated with the transaction.
 * @param {string} token - Authorization token for the request.
 *
 * @returns {Promise<void>} A promise that resolves when the card saving operation is completed.
 * @throws {Error} Logs the error if the PUT request fails.
 */
const basysSaveCardPutRequest = async (
  accName,
  basysCustomerID,
  monerisCc,
  sendrespons,
  endpoint,
  guid,
  corpid,
  token
) => {
  let payload = sendrespons;
  try {
    payload.IsCcSaved = true;
    payload.TheMonerisCC = monerisCc;
    payload = {
      ...payload,
      BasysCustomerID: basysCustomerID,
      guid: guid,
      corp_id: corpid,
    };

    const { data, status } = await axios.put(
      `${endpoint}/Put?id=${guid}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Origin_Custom: endpoint,
        },
      }
    );

    storeLog(
      "info",
      `Save Card basys PUT request: "${JSON.stringify(payload)}"`,
      status,
      guid,
      corpid,
      "Save Card",
      `Basys Save Card for ${accName}`,
      "Success"
    );
  } catch (err) {
    storeLog(
      "error",
      `Save Card basys PUT request: "${JSON.stringify(payload)}"`,
      500,
      guid,
      corpid,
      "Save Card",
      `Basys Save Card for ${accName}`,
      "Failure"
    );
    console.warn(err);
  }
};

/**
 * Creates a customer or saves card details for a customer in the Basys system.
 *
 * - If the customer already exists, it saves the card details and optionally processes the payment.
 * - If the customer does not exist, it creates a new customer account and associates the card with the account.
 * - Validates the form data and handles both the card save and payment flow.
 * - Stores the card number and expiration date in local storage to persist across requests.
 *
 * @param {string} accName - The account name for the customer.
 * @param {boolean} fromPayment - Indicates whether the card save is initiated from a payment process.
 * @param {Object} basysCustomerID - Reference to the Basys customer ID.
 * @param {Function} setLoading - Function to control the loading state.
 * @param {Object} basysAccountInfo - Account information for the Basys customer.
 * @param {boolean} isBasysTesting - Indicates if the Basys environment is in testing mode.
 * @param {Object} basysBillingAddrInfo - Billing address information for the customer.
 * @param {Object} formData - Form data containing payment details like card number and expiration date.
 * @param {string} basysApiToken - API token for Basys authentication.
 * @param {Object} sendrespons - The response object to update and send with the request.
 * @param {string} endpoint - The API endpoint URL.
 * @param {string} guid - GUID for the transaction.
 * @param {string} corpid - CorpID associated with the transaction.
 * @param {string} token - Authorization token for the request.
 * @param {Function} setShowCardSaveModal - Function to control the display of the "Save Card" modal.
 * @param {Function} setIsCardSaveOpen - Function to control the state of card save confirmation.
 * @param {Function} setCardHasBeenSaved - Function to set the state indicating that the card has been saved.
 * @param {Function} setErrors - Function to set validation errors.
 * @param {Array<Object>} invoiceItems - List of invoice items being processed.
 * @param {Object} paynow - Payment options related to the current transaction.
 * @param {Array<number>} selectedRows - Selected rows representing items being paid.
 * @param {Function} setFailedIsOpen - Function to control the display of the failure modal.
 * @param {Function} setISOpen - Function to control the display of the success modal.
 * @param {Object} root_data - Root data used during the transaction.
 * @param {Function} setPaymentFailureReason - Function to set the reason for payment failure.
 * @param {Function} setIsCardSaveFailedOpen - Function to control the display of the card save failure modal.
 *
 * @returns {Promise<void>} A promise that resolves when the customer is created and/or card details are saved.
 * @throws {Error} Throws an error if the customer creation or card save process fails.
 */
export const createCustomer = async (
  accName,
  getCardId,
  fromPayment,
  basysCustomerID,
  setLoading,
  basysAccountInfo,
  isBasysTesting,
  basysBillingAddrInfo,
  formData,
  basysApiToken,
  sendrespons,
  endpoint,
  guid,
  corpid,
  token,
  setShowCardSaveModal,
  setIsCardSaveOpen,
  setCardHasBeenSaved,
  setErrors,
  invoiceItems,
  paynow,
  selectedRows,
  setFailedIsOpen,
  setISOpen,
  root_data,
  setPaymentFailureReason,
  setIsCardSaveFailedOpen
) => {
  if (!validateForm(formData, setErrors)) {
    return;
  }
  setShowCardSaveModal(false);
  setLoading(true);
  const [cardId, cardInfo] = await getCardId();
  if (cardId !== "" && basysCustomerID.current) {
    await basysSaveCardPutRequest(
      accName,
      basysCustomerID.current,
      {
        CcType: cardInfo?.card_type,
        First4Num: cardInfo?.masked_number?.substring(0, 4),
        Last4Num: cardInfo?.masked_number?.slice(-4),
        ExpYear: cardInfo?.expiration_date?.substring(3),
        ExpMonth: cardInfo?.expiration_date?.substring(0, 2),
      },
      sendrespons,
      endpoint,
      guid,
      corpid,
      token
    );
    setLoading(false);
    setIsCardSaveOpen(true);
    setCardHasBeenSaved(true);
    return;
  }
  let CardNumber;
  let ExpiryDate;
  localStorage.setItem("cardnumber", formData.cardNumber);
  localStorage.setItem("expir_date", formData.expiry);
  CardNumber = localStorage.getItem("cardnumber");
  ExpiryDate = localStorage.getItem("expir_date");

  if (CardNumber && ExpiryDate) {
    if (basysCustomerID.current) {
      try {
        const payload = {
          customer_id: basysCustomerID.current,
          number: CardNumber.replace(/-/g, ""),
          expiration_date: ExpiryDate.replace(/\//g, ""),
          pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
          IsTesting: isBasysTesting,
          guid: guid,
          corp_id: corpid,
          action_type: fromPayment
            ? `Basys save card from payment`
            : `Basys save card`,
        };

        const { data } = await axios.post(
          `${API_BASE_URL}/basys/vault-customer/save-card-for-id`,
          payload,
          {
            headers: {
              Authorization: basysApiToken,
              "Content-Type": "application/json",
            },
          }
        );

        if (data?.status !== "success") {
          setPaymentFailureReason(JSON.stringify(data));
          if (fromPayment) {
            setFailedIsOpen(true);
          } else {
            setIsCardSaveFailedOpen(true);
          }
          throw new Error(`Failed to save card`);
        }

        if (data?.status === "success" && !fromPayment) {
          let cardInfo = {};
          if (
            data?.data?.data?.customer?.payments?.cards?.length &&
            data?.data?.data?.customer?.payments?.cards?.length > 0
          ) {
            cardInfo =
              data?.data?.data?.customer?.payments?.cards[
                data?.data?.data?.customer?.payments?.cards.length - 1
              ];
          }

          await basysSaveCardPutRequest(
            accName,
            data?.data?.id,
            {
              CcType: cardInfo?.card_type,
              First4Num: cardInfo?.masked_number?.substring(0, 4),
              Last4Num: cardInfo?.masked_number?.slice(-4),
              ExpYear: cardInfo?.expiration_date?.substring(3),
              ExpMonth: cardInfo?.expiration_date?.substring(0, 2),
            },
            sendrespons,
            endpoint,
            guid,
            corpid,
            token
          );
          setIsCardSaveOpen(true);
          setCardHasBeenSaved(true);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.warn(err);
      }
    } else {
      try {
        // Check if customer account exists with the company name
        const companyName = basysAccountInfo?.AccountName;
        const response = await axios.get(
          `${API_BASE_URL}/basys/vault-customer/get?action_type=IsCompanyRegisteredGetCall&pg_token=uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo&IsTesting=${isBasysTesting}&customer_id=${companyName.replace(
            /[@#$%^&*()!~']/g,
            " "
          )}`,
          {
            headers: {
              Authorization: basysApiToken,
              "Content-Type": "application/json",
            },
          }
        );

        let customerData;

        customerData = {
          id_format: "xid_type_last4",
          description: basysAccountInfo?.AccountName,
          flags: ["surcharge_exempt"],
          default_payment: {
            card: {
              number: CardNumber.replace(/-/g, ""),
              expiration_date: ExpiryDate.replace(/\//g, ""),
            },
          },
          pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
          IsTesting: isBasysTesting,
        };

        if (!response || response.data?.status !== "success") {
          // Account does not exist
          customerData.id = companyName.replace(/[@#$%^&*()!~']/g, " ");
        }
        customerData.default_billing_address = basysBillingAddrInfo;
        const { data } = await axios.post(
          `${API_BASE_URL}/basys/vault-customer/save-card-and-customer`,
          customerData,
          {
            headers: {
              Authorization: basysApiToken,
              "Content-Type": "application/json",
            },
          }
        );

        if (data?.status !== "success") {
          setPaymentFailureReason(JSON.stringify(data));
          if (fromPayment) {
            setFailedIsOpen(true);
          } else {
            setIsCardSaveFailedOpen(true);
          }
          throw new Error(`Failed to create customer`);
        }

        if (data?.status === "success" && fromPayment) {
          basysCustomerID.current = data?.data?.id;
          handleBasysCheckout(
            accName,
            data?.data?.data?.customer?.payments?.cards[0]?.id,
            setLoading,
            invoiceItems,
            basysCustomerID,
            basysAccountInfo,
            paynow,
            isBasysTesting,
            basysBillingAddrInfo,
            formData,
            basysApiToken,
            selectedRows,
            sendrespons,
            endpoint,
            guid,
            corpid,
            token,
            setFailedIsOpen,
            setISOpen,
            root_data,
            setErrors,
            setPaymentFailureReason
          );
        } else {
          let cardInfo = {};
          if (
            data?.data?.data?.customer?.payments?.cards?.length &&
            data?.data?.data?.customer?.payments?.cards?.length > 0
          ) {
            cardInfo =
              data?.data?.data?.customer?.payments?.cards[
                data?.data?.data?.customer?.payments?.cards.length - 1
              ];
          }
          await basysSaveCardPutRequest(
            accName,
            data?.data?.id,
            {
              CcType: cardInfo?.card_type,
              First4Num: cardInfo?.masked_number?.substring(0, 4),
              Last4Num: cardInfo?.masked_number?.slice(-4),
              ExpYear: cardInfo?.expiration_date?.substring(3),
              ExpMonth: cardInfo?.expiration_date?.substring(0, 2),
            },
            sendrespons,
            endpoint,
            guid,
            corpid,
            token
          );
          setLoading(false);
          setIsCardSaveOpen(true);
          setCardHasBeenSaved(true);
        }
      } catch (error) {
        setPaymentFailureReason("Failed to create customer");
        if (fromPayment) {
          setFailedIsOpen(true);
        } else {
          setIsCardSaveFailedOpen(true);
        }
        setLoading(false);
        setShowCardSaveModal(false);
        console.error("Error occurred while creating customer:", error);
        // Handle error
      }
    }
  }
};
